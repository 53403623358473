import randomstring from 'randomstring';
import { createHash } from 'crypto';

export { AuthContext, withAuth, useAuth } from './AuthContext';
export { AuthProvider } from './AuthProvider';
export const generateCodeChallenge = (code) =>
  createHash('sha256').update(code).digest('hex');
export const createPKCECodes = () => {
  const state = randomstring.generate();
  const codeVerifier = randomstring.generate(128);
  const codeChallenge = generateCodeChallenge(codeVerifier);
  const createdAt = new Date();
  const codePair = {
    state,
    codeVerifier,
    codeChallenge,
    createdAt,
  };
  return codePair;
};
