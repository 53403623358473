/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import { HOME_PAGE_URL, CUSTOMER_ID } from 'src/config';
import { fetchPerms } from './actions/accountActions';
import hasPerm from './utils/hasPerm';
import * as permissions from './constants/permissions';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/app" />,
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to={HOME_PAGE_URL} />,
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView')),
      },
      {
        exact: true,
        path: '/app/boards',
        component: () => <Redirect to={HOME_PAGE_URL} />,
      },
      {
        exact: true,
        perm: 'view_job_report_page',
        path: '/app/reports/jobs/job',
        component: lazy(() => import('src/views/reports/JobsListView')),
      },
      {
        exact: true,
        perm: 'view_remote_commissioning',
        path: '/app/reports/remote-commission',
        component: lazy(() => import('src/views/reports/RemoteCommission')),
      },
      {
        exact: true,
        perm: permissions.CREATE_JOB,
        path: '/app/jobs/job/create',
        component: lazy(() => import('src/views/pages/Job/create')),
      },
      {
        exact: true,
        path: '/app/jobs/job/:jobId',
        component: lazy(() => import('src/views/pages/JobDetailView')),
      },
      {
        exact: true,
        path: '/app/imported-files/pre-built-exports',
        component: lazy(() =>
          import('src/views/pages/ImportedFile/PreBuiltExportList')
        ),
      },
      {
        exact: true,
        path: '/app/imported-files/pre-built-exports/:prebuiltExportSectionId',
        component: lazy(() =>
          import('src/views/pages/ImportedFile/PreBuiltExportList')
        ),
      },
      // imported assets
      {
        exact: true,
        perm: (perms) =>
          !!perms.find((p) =>
            permissions.VIEW_ASSET_IMPORTER_PERMS.includes(p)
          ),
        path: '/app/imported-files/assets',
        extraProps: { category: 'assets' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        perm: (perms) =>
          !!perms.find((p) =>
            permissions.VIEW_ASSET_IMPORTER_PERMS.includes(p)
          ),
        path: '/app/imported-files/assets/:importer',
        extraProps: { category: 'assets' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        perm: (perms) =>
          !!perms.find((p) =>
            permissions.VIEW_ASSET_IMPORTER_PERMS.includes(p)
          ),
        path: '/app/imported-files/assets/:importer/:id',
        extraProps: { category: 'assets' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileDetails')
        ),
      },
      // imported jobs
      {
        exact: true,
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_JOB_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/jobs',
        extraProps: { category: 'jobs' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_JOB_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/jobs/:importer',
        extraProps: { category: 'jobs' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_JOB_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/jobs/:importer/:id',
        extraProps: { category: 'jobs' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileDetails')
        ),
      },
      // imported vans
      {
        exact: true,
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_VAN_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/vans',
        extraProps: { category: 'vans' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_VAN_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/vans/:importer',
        extraProps: { category: 'vans' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_VAN_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/vans/:importer/:id',
        extraProps: { category: 'vans' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileDetails')
        ),
      },
      // imported flows
      {
        exact: true,
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_FLOW_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/flows',
        extraProps: { category: 'flows', acceptedFileExt: '.usr' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_FLOW_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/flows/:importer',
        extraProps: { category: 'flows', acceptedFileExt: '.usr' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileList')
        ),
      },
      {
        exact: true,
        perm: (perms) =>
          !!perms.find((p) => permissions.VIEW_FLOW_IMPORTER_PERMS.includes(p)),
        path: '/app/imported-files/flows/:importer/:id',
        extraProps: { category: 'flows' },
        component: lazy(() =>
          import('src/views/pages/ImportedFile/ImportedFileDetails')
        ),
      },
      // users
      {
        exact: true,
        perm: 'view_manage_users_page',
        path: '/app/users',
        component: lazy(() => import('src/views/pages/User/ListView/UserList')),
      },
      {
        exact: true,
        perm: 'view_user_audit_logs',
        path: '/app/users-audit',
        component: lazy(() =>
          import('src/views/pages/User/ListView/UserAudit')
        ),
      },
      {
        exact: true,
        perm: 'view_manage_users_page',
        path: '/app/users/roles',
        component: lazy(() => import('src/views/pages/User/roles')),
      },
      {
        exact: true,
        path: '/app/users/:id',
        component: lazy(() => import('src/views/pages/User/UserDetails')),
      },
      {
        exact: true,
        path: '/app/dd',
        perm: 'view_data_dictionary',
        component: lazy(() => import('src/views/pages/DD/DDQuestionList')),
      },
      {
        exact: true,
        perm: 'view_data_dictionary',
        path: '/app/dd/questions/:id',
        component: lazy(() => import('src/views/pages/DD/DDQuestionDetails')),
      },
      {
        exact: true,
        path: '/app/reports/jobs/job',
        component: lazy(() => import('src/views/reports/JobsListView')),
      },
      {
        exact: true,
        path: '/app/reports/commission',
        perm: 'view_commission_report_page',
        component: lazy(() => import('src/views/reports/Commission')),
      },
      {
        exact: true,
        path: '/app/reports/material-numbers',
        perm: 'view_material_number_report_page',
        component: lazy(() => import('src/views/reports/MaterialNumbers')),
      },
      {
        exact: true,
        path: '/app/reports/inventory/asset-details',
        perm: 'view_asset_report_page',
        component: lazy(() =>
          import('src/views/reports/InventoryAssetDetailsListView')
        ),
      },
      {
        exact: true,
        path: '/app/inventory/asset/:id',
        component: lazy(() => import('src/views/pages/Asset/view')),
      },
      {
        exact: true,
        path: '/app/inventory/asset',
        component: lazy(() => import('src/views/pages/Asset/view')),
      },
      {
        exact: true,
        perm: 'view_job_outcome_page',
        path: '/app/reports/job-outcome',
        component: lazy(() => import('src/views/reports/JobOutcomeListView')),
      },
      {
        exact: true,
        perm: 'view_schedule_page',
        path: '/app/reports/jobs/board',
        component: lazy(() => import('src/views/reports/JobsBoard')),
      },
      {
        exact: true,
        // customers: ['maas'],
        perm: 'view_flow_records',
        path: '/app/flows/industry-flow-tracking',
        component: lazy(() => import('src/views/pages/Flow/FlowRecordList')),
      },
      {
        exact: true,
        perm: 'view_flow_triage_page',
        path: '/app/flows/flow-triage',
        component: lazy(() =>
          import('src/views/pages/Flow/FlowTriageRecordList')
        ),
      },
      {
        exact: true,
        // customers: ['maas'],
        perm: 'view_flow_records',
        path: '/app/flows/records/:id',
        component: lazy(() => import('src/views/pages/Flow/FlowRecordDetails')),
      },

      {
        exact: true,
        // customers: ['maas'],
        perm: 'view_flowcontainer',
        path: '/app/flows/dataflow-exceptions',
        extraProps: { status: 'nack_enack_received' },
        component: lazy(() => import('src/views/pages/Flow/FlowContainerList')),
      },
      {
        exact: true,
        // customers: ['maas'],
        perm: 'view_flowcontainer',
        path: '/app/flows/containers/:id',
        component: lazy(() =>
          import('src/views/pages/Flow/FlowContainerEditor')
        ),
      },

      {
        exact: true,
        customers: ['maas'],
        perm: 'view_flow_builder_page',
        path: '/app/flows/flow-builder',
        component: lazy(() => import('src/views/reports/FlowBuilder')),
      },
      {
        exact: true,
        customers: ['maas', 'dev'],
        perm: 'view_activity_report',
        path: '/app/reports/activity',
        component: lazy(() => import('src/views/reports/Activity')),
      },
      {
        exact: true,
        path: '/app/reports/vans/van',
        perm: 'view_van_report_page',
        component: lazy(() => import('src/views/reports/VanListView')),
      },
      {
        exact: true,
        path: '/app/reports/vans/van-accuracy',
        perm: 'view_van_audit_accuracy',
        component: lazy(() =>
          import('src/views/pages/VanAuditAccuracy/VanAuditAccuracyView')
        ),
      },
      {
        exact: true,
        path: '/app/reports/vans/van-job-details/:jobId',
        perm: 'view_van_audit_job_details',
        component: lazy(() =>
          import('src/views/pages/NonMeteringJobDetailView')
        ),
      },
      {
        exact: true,
        path: '/app/vans/:id',
        component: lazy(() => import('src/views/pages/Van/VanDetails')),
      },
      {
        exact: true,
        customers: ['dev', 'maas'],
        perm: 'view_notificationconfig',
        path: '/app/notifications/manage',
        component: lazy(() =>
          import('src/views/reports/ManageNotificationView')
        ),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

const renderComponent = (route, perms, isStaff, props) => {
  const permCheckPassed = route.perm ? hasPerm(route.perm, perms) : true;
  const customerCheckPassed = route.customers?.length
    ? route.customers.includes(CUSTOMER_ID)
    : true;

  const isHomePage = route.path === HOME_PAGE_URL;

  const staffOverride = route.staffOnly ? isStaff : undefined;

  const isAllowed =
    staffOverride ?? (isHomePage || (permCheckPassed && customerCheckPassed));

  if (!isAllowed) {
    return <Redirect to={HOME_PAGE_URL} />;
  }

  const Component = route.component;
  const withExtraProps = { ...props, ...(route.extraProps || {}) };
  return <Component {...withExtraProps} />;
};

const renderRoutes = (routes, perms, isStaff) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes
                      ? renderRoutes(route.routes, perms, isStaff)
                      : renderComponent(route, perms, isStaff, props)}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  React.useEffect(() => {
    dispatch(fetchPerms());
  }, []);

  return account.perms.loaded ? (
    renderRoutes(routesConfig, account.perms.perms, account.user?.is_superuser)
  ) : (
    <LoadingScreen />
  );
}

export default Routes;
