import Axios from 'axios';
import { APP_VERSION, UMESH_API_DOMAIN } from 'src/config';
import qs from 'qs';

const onRequest = (config) => {
  const { token } = JSON.parse(window.localStorage.getItem('auth')) || {};

  if (config.headers) {
    // custom header to indicate portal app version
    config.headers.set('app-version', `umesh-ui@${APP_VERSION}`);
    if (config.withToken ?? true) {
      // optionally include auth token in request
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  // we only care about the data and can ignore metadata like headers, request config, and etc.
  return response.data;
};

const onResponseError = (error) => {
  return Promise.reject(error);
};

export const api = Axios.create({
  baseURL: `${window.location.protocol}//${UMESH_API_DOMAIN}`,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' }),
});
// Request Interceptor
api.interceptors.request.use(onRequest, onRequestError);
// Response Interceptor
api.interceptors.response.use(onResponse, onResponseError);
