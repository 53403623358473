import React from 'react';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import {
  FIREBASE_ENABLED,
  FIREBASE_CONFIG,
  FIREBASE_VAPID_PUBLIC_KEY,
} from 'src/config';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { useAuth } from './uAuth';
import { api } from 'src/lib/api-client';

// Initialize Firebase
const firebaseApp = FIREBASE_ENABLED ? initializeApp(FIREBASE_CONFIG) : null;
export const messaging = FIREBASE_ENABLED ? getMessaging(firebaseApp) : null;

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

const FirebaseCloudMessaging = () => {
  const user = useSelector((state) => state.account.user);
  const { authService } = useAuth();
  const [pushNotification, setPushNotification] = React.useState(null);

  React.useEffect(() => {
    if (!pushNotification) return;
    new Notification(pushNotification.title, { body: pushNotification.body }); // eslint-disable-line no-new
  }, [pushNotification]);

  /* eslint-disable-next-line no-unused-vars */
  FIREBASE_ENABLED
    ? onMessageListener().then(({ notification, ...payload }) => {
        setPushNotification(notification);
      })
    : null;

  if (authService.isAuthenticated() && user?.is_superuser) {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        // get FCM registration token
        getToken(messaging, { vapidKey: FIREBASE_VAPID_PUBLIC_KEY })
          .then((currentToken) => {
            if (currentToken) {
              api.post(
                "/register-fcm-device",
                { type: 'web', registration_id: currentToken }
              );
            }
          })
          .catch((err) => {
            Sentry.withScope((scope) => {
              scope.setLevel('debug');
              scope.setUser({ id: user.id });
              Sentry.captureException(err);
            });
          });
      }
    });
  }

  return null;
};

export default FirebaseCloudMessaging;
